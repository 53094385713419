import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import Research1 from "../assets/images/why-choose-us-image/main-static.png";
import Research2 from "../assets/images/ml-banner-image/ml-main-pic.png";

const Research = () => (
    <Layout>
        <SEO title="Research" /> 

        <Navbar />


        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                        <div className="features-details-desc">
                        <h3>Research & Patenting</h3>
                        <p>From day one, Faraya is committed to protecting your intellectual property and ensuring that your developed solution is informed by the most relevant research in the field. That is why we have a dedicated team of PhD researchers and patent attorneys to guarantee your solution is unique and stays that way. </p>
    
                        <ul className="features-details-list">
                            <li>Prior Art Searches</li>
                            <li>Patent Documentation and Filings</li>
                            <li>Technical Literature Reviews</li>
                            <li>White Papers</li>
                        </ul>
                    </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Research1} 
                            height={400}
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Research2} 
                            width={450}
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <p>Our team is able to provide a prior art search at the onset of new product developments. This allows are development team to learn from what exists and design a product that is novel, and worthy of being patented. Our team will ensure that your Intellectual Property is protected properly, as we offer patent development and submission services.</p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Who Conducts Research for Our Project?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            To complement our internal team, Faraya is tapped into universities around the world, and we utilize the expertise of faculty and graduate students to support our research efforts.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Can You File A Patent For Our Product/Idea?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, we provide full support from conducting patent searches to filing provisional and non-provisional patent applications.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Do I Have to Leverage this Service?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            No. Our services are meant to support the full lifecycle of product design, development and deployment, however, each service is optional.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Research;